import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Paper,
    Grid,
    FormControlLabel,
    Checkbox,
    TextField,
    Button,
    Typography,
    Chip,
    Tooltip,
    IconButton
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import { ComponentType, FORM_COMPONENT_TYPES } from "../../utils";
import ComponentUIPreview from './componentUIPreview';

import { DependencyTable, CustomTable, CustomTableFixedRow } from "../../components";

// for drag and drop 
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const componentsNote = {
    "range_picker": "Provide Range (Eg. 0, 10)"
}

const useStyles = makeStyles((theme) => ({
    formFields: {
        paddingBottom: "20px",
        border: "1px solid rgb(224, 224, 224)",
        background: "rgb(245, 245, 245)",
    },
    inputFiled: {
        marginBottom: "16px"
    },
    selectComponentList: {
        marginTop: 0,
        color: "red"
    },
    addFloatingButton: {
        position: "absolute",
        right: "26px",
        bottom: "30px"
    },
    embtyMesg: {
        textAlign: "center",
        padding: "20px 10px"
    },
    optionNote: {
        textAlign: "end",
        fontSize: "12px",
        paddingRight: "6px"
    },
    previewIcon: {
        marginLeft: 4,
        marginTop: 4,
        cursor: "pointer"
    },
    root: {
        padding: 12
    },
    paperRoot: {
        padding: "8px 16px 16px 16px",
        border: "1px solid #E0E0E0"
    },
    header: {
        display: "flex"
    },
    slNo: {
        marginRight: 8
    },
    componentList: {
        width: 300
    },
    headerRoot: {
        marginBottom: 16
    },
    addDependency: {
        marginTop: 12
    }
}));



export default function FormLayer(props) {

    const classes = useStyles();

    const [formComponentList, setFormComponentList] = React.useState(null);

    // Declaring Store dispatch
    const allComponents = FORM_COMPONENT_TYPES;

    React.useLayoutEffect(() => {
        const initialDataFetch = async () => {
            const data = allComponents?.filter(val => val.value !== "popup_switch")
            setFormComponentList(data)
        }
        initialDataFetch();
    }, [props.id, allComponents])

    // ComponentUIPreview
    const initalState = {
        anchorEl: null,
        data: null
    }

    const [state, setState] = React.useState(initalState);

    const handlePopoverOpen = (event, data) => {
        setState({ ...state, anchorEl: event.currentTarget, data: data });
    };

    const handlePopoverClose = () => {
        setState(initalState);
    };

    const open = Boolean(state.anchorEl);


    // console.log(props.fromData, "props?.fromData")

    return (<>

        <DragDropContext onDragEnd={(params) => {
            const srcI = params?.source?.index;
            const descI = params?.destination?.index;
            props?.fromData?.splice(descI, 0, props?.fromData?.splice(srcI, 1)[0]);
        }}>

            {/* {props?.fromData?.length > 0 ? props?.fromData?.map((val, index) => {
            if (val.component === "popup_switch") {
                return false
            } */}

            <Droppable droppableId="droppable-1">
                {(provided, _) => (
                    <div ref={provided.innerRef}   {...provided.droppableProps}>

                        {props?.fromData?.length > 0 ? props?.fromData?.map((val, index) => {

                            if (val.component === "popup_switch") {
                                return false
                            }
                            return (


                                // <Grid key={index} className={classes.root}>
                                //     <Paper elevation={0} className={classes.paperRoot}>
                                //         <Grid
                                //             container
                                //             direction="row"
                                //             justify="space-between"
                                //             alignItems="center"
                                //             className={classes.headerRoot}
                                //         >

                                //             <Grid item className={classes.header}>

                                //                 <Typography
                                //                     className={classes.slNo}
                                //                     component={"span"}
                                //                     color={"textPrimary"}>
                                //                     {index + 1 - props.indexOfno}{"."}
                                //                 </Typography>


                                <Draggable key={val.id} draggableId={"draggable-" + val.id} index={index}>
                                    {(provided, _) => (
                                        <div ref={provided.innerRef}  {...provided.draggableProps} key={val.id} >

                                            <Grid key={index} style={{ padding: "20px 20px 0px 20px" }} >

                                                <Paper style={{ padding: "8px 20px 3px 20px", border: "1px solid #E0E0E0" }} elevation={0} >

                                                    <Grid container direction="row" justify="space-between" alignItems="center" style={{ marginBottom: "20px" }}>

                                                        <Grid item style={{ display: "flex" }}>

                                                            <Grid style={{ minWidth: "26px" }}>
                                                                <Typography component={"span"} color={"textPrimary"}>{index + 1 - props.indexOfno}{"."}</Typography>

                                                            </Grid>

                                                            <Autocomplete
                                                                className={classes.componentList}
                                                                onChange={(e, newValue) => props.onChangeComponents(newValue?.value, index, "component")}
                                                                value={formComponentList?.filter(getvalue => getvalue.value === val?.component)[0] ?? null}
                                                                options={formComponentList}
                                                                getOptionLabel={(option) => option.label}
                                                                id="clear-on-escape"
                                                                clearOnEscape
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        error={val?.error?.componentError ?? false}
                                                                        {...params}
                                                                        className={classes.selectComponentList}
                                                                        size="small"
                                                                        placeholder={"Select Question type"}
                                                                        fullWidth
                                                                        margin={"dense"}
                                                                    />}
                                                            />

                                                            <VisibilityRoundedIcon
                                                                className={classes.previewIcon}
                                                                color={"action"}
                                                                fontSize={'small'}
                                                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                                                aria-haspopup="true"
                                                                onMouseEnter={(e) => handlePopoverOpen(e, val)}
                                                                onMouseLeave={handlePopoverClose}
                                                            />

                                                        </Grid>

                                                        <Grid item style={{ display: "flex", alignItems: "baseline" }}
                                                        >

                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={val?.required ?? false}
                                                                        onChange={(e) => props.onChangeComponents(!val?.required ?? false, index, "required")}
                                                                        name="checkedA"
                                                                    />
                                                                }
                                                                label="Required"
                                                            />
                                                            <Tooltip title="Delete Question" placement="top">
                                                                <IconButton

                                                                    onClick={() => props.delectQuestion(index)}>
                                                                    <DeleteOutlineOutlinedIcon color="error" />

                                                                </IconButton>
                                                            </Tooltip>
                                                            <Grid {...provided.dragHandleProps} >
                                                                <Tooltip title="Drag Here" placement="top">

                                                                    <FlipToFrontIcon />
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        {/* <Grid {...provided.dragHandleProps} >
                                                            <Tooltip title="Drag Here" placement="top">

                                                                <FlipToFrontIcon />
                                                            </Tooltip>
                                                        </Grid> */}

                                                    </Grid>

                                                    <Grid
                                                        item
                                                        container
                                                        direction="row"
                                                        spacing={2}
                                                    >
                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.inputFiled}>

                                                            <TextField
                                                                value={val?.label ?? ""}
                                                                onChange={(e) => props.onChangeComponents(e.target.value, index, "label")}
                                                                fullWidth
                                                                label="Question / Title"
                                                                id="question"
                                                                variant="outlined"
                                                                size="small"
                                                                error={val?.error?.labelError ?? false}
                                                                helperText={val?.error?.labelError ? "Please give the question (must be more then two character)" : ""}
                                                            />

                                                        </Grid>

                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.inputFiled}>

                                                            <TextField
                                                                value={val?.tag ?? ""}
                                                                onChange={(e) => props.onChangeComponents(e.target.value, index, "tag")}
                                                                fullWidth
                                                                label="#Tag"
                                                                id="tag"
                                                                variant="outlined"
                                                                size="small"
                                                                error={val?.error?.tagError ?? false}
                                                                helperText={val?.error?.tagError ? "Please give the tag (must be more then two character)" : ""}
                                                            />

                                                        </Grid>

                                                    </Grid>

                                                    {ComponentType(val?.component ?? "") &&
                                                        <Grid item className={classes.inputFiled}>

                                                            <Typography color={"textSecondary"} className={classes.optionNote}>
                                                                {componentsNote[val?.component] ?? "UI - Separated by a enter / use multiple tags"}
                                                            </Typography>

                                                            <Autocomplete
                                                                multiple
                                                                id="tags-outlined"
                                                                size={"small"}
                                                                options={[]}
                                                                value={val?.options}
                                                                freeSolo
                                                                onChange={(e, newValue) => props?.onChangeComponents(newValue, index, "options")}
                                                                renderTags={(value, getTagProps) =>
                                                                    value?.map((option, index) => (
                                                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                                    ))
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        label="Options" placeholder="Options"
                                                                        error={val?.error?.optionError ?? false}
                                                                        helperText={val?.error?.optionError ? "Please add minimum two options required" : ""}
                                                                    />
                                                                )}
                                                            />

                                                        </Grid>
                                                    }

                                                    {
                                                        ComponentType(val?.component ?? "") && <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={val?.has_dependency_qn ?? false}
                                                                        onChange={(e) => props?.onChangeComponents(!val?.has_dependency_qn ?? false, index, "has_dependency_qn")}
                                                                        name="hasDependencyQn"
                                                                    />
                                                                }
                                                                label="Show another question / form / section based on answer"
                                                            />
                                                        </Grid>
                                                    }

                                                    {
                                                        val?.component === "custom_table" &&
                                                        <CustomTable
                                                            data={val}
                                                            onChangeComponents={(value) => props?.onChangeComponents(value, index, 'tableScheme')}
                                                        />
                                                    }
                                                    {
                                                        val?.component === "custom_table_2" &&
                                                        <CustomTableFixedRow
                                                            data={val}
                                                            onChangeComponents={(value) => props?.onChangeComponents(value, index, 'tableScheme')}
                                                        />
                                                    }

                                                    {
                                                        val?.has_dependency_qn && <Grid item>

                                                            <DependencyTable
                                                                data={val?.dependency}
                                                                options={val?.options}
                                                                parentSection={props?.parentSection}
                                                                selectedSection={props?.selectedSection}
                                                                quetions={props?.fromData}
                                                                removeDependency={props?.addDeleteComponents}
                                                                updateDependency={props?.updateDependency}
                                                                parentIndex={index}
                                                            />

                                                            <Button
                                                                className={classes.addDependency}
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                color={"primary"}
                                                                onClick={() => props?.addDeleteComponents(val, index, true)}
                                                            >
                                                                + Add
                                </Button>
                                                        </Grid>
                                                    }

                                                </Paper>

                                            </Grid>

                                        </div>
                                    )}
                                </Draggable>
                            )
                        }) :
                            <Typography className={classes.embtyMesg} color={"textSecondary"} >You are yet to add a Question to this form</Typography>
                        }
                        <ComponentUIPreview
                            open={open}
                            data={state?.data}
                            anchorEl={state?.anchorEl}
                            handlePopoverClose={handlePopoverClose}
                        />

                    </div>
                )}
            </Droppable>

        </DragDropContext>




    </>
    );
}
