/**
 * @author Hari Ram
 * @email hariram@qdmplatforms.com
 * @create date 2021-01-28
 * @modify date 2021-01-29
 * @desc Input component
 */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField , Typography} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  textField: {},
}));
const getLabel=(props)=>{
  return <Typography variant="body1">{props.label} {props.isrequired && <Typography variant="caption" style={{color:"red"}}>*</Typography>}</Typography>
}

const DateTime = (props) => {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      id={props.id}
      label={getLabel(props)}
      type={props.type}
      defaultValue={props.defaultValue}
      value={props.value}
      className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{ readOnly:props.isReadonly ?? false }}
      size="small"
      onChange={(e) => props.onChange(e.target.value)}
      variant={"outlined"}
    />
  );
};
DateTime.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  //   variant: PropTypes.oneOf(["Standard" | "filled" | "outlined"]),
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  isReadonly: PropTypes.bool
};
DateTime.defaultProps = {
  label: "label",
  //   variant: "outlined",
  defaultValue: null,
  type: "date",
};
export default DateTime;
