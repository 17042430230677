import React from 'react'
import {
  Grid, Button, Typography, Avatar, Popover,
  List as MUList,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import { Delete, MoreVert } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from 'prop-types';
export * from './loading';
export * from './cardListLoader';

const useStyles = makeStyles(theme => ({
  rowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  grow: {
    flex: 1
  },
  itemSpacing: {
    padding: 10,
    position: 'relative',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: '0px 15px 25px #110F4712',
    borderRadius: 10,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      padding: '12px 6px'
    }
  },
  buttonSpacing: {
    marginLeft: 10,
    [theme.breakpoints.down('xs')]: {
      // margin: "6px auto !important"
    }
  },
  btn: {
    boxShadow: '0px 5px 23px #00000014',
    fontSize: 13,
    textTransform: 'capitalize',
    letterSpacing: 1,
    '&:hover': {
      // background: '#45149B'
    }
  },
  mobViewColumn: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  listItemIcon: {
    minWidth: 35
  },
  absPoisiton: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      right: 0,
      top: 0
    },
  },
  titleSubTitle: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column'
    },
  },
  justifyBtn: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
      width: '100%',
      padding: 10
    },
  },
  mobViewPaddingLeft: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 12
    },
  },
}))

export const CardList = props => {

  const classes = useStyles(props);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Grid item xs={12}>

      {props.type === '5' && (
        <Grid
          item
          xs={12}
          className={`${classes.rowCenter} ${classes.itemSpacing} ${classes.mobViewColumn}`}
        >

          <div className={`${classes.grow} ${classes.mobViewPaddingLeft}`} >
            <div className={`${classes.rowCenter}`}>

              {!props.hideAvatar && <Avatar
                style={{ marginRight: 16 }}
                variant="rounded"
                src={props.src}
                alt={props.alt} >
                {props.alt}
              </Avatar>}

              <div>
                <Typography variant='body1' className={classes.titleSubTitle}>
                  {props.title}
                </Typography>

                {props.show_sub_title && <Typography component="p" variant="caption">{props.sub_title}</Typography>}
              </div>


            </div>
          </div>

          <div className={`${classes.rowCenter} ${classes.justifyBtn}`}>
            {!props?.hideMapBtn && <div className={classes.buttonSpacing}>
              <Button
                variant='outlined'
                color='primary'
                className={classes.btn}
                onClick={props.onMapBtnClicked}
              >
                {props?.mapBtnName ?? "Map"}
              </Button>
            </div>}
            {!props?.hideActionBtn && <div className={classes.buttonSpacing}>
              <Button
                variant='outlined'
                color='primary'
                className={classes.btn}
                onClick={props.onActionBtnClicked}
              >
                {props?.actionBtnName ?? "Action"}
              </Button>
            </div>}
          </div>
          {/* <div className={classes.marginRight}></div> */}

          {props.isMoreBtn && (
            <div className={classes.absPoisiton}>
              <IconButton aria-describedby={id} onClick={handleClick}>
                <MoreVert />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MUList dense>
                  {/* Edit Icon */}
                  {!props?.hideEditBtn && <ListItem
                    dense
                    button
                    onClick={() => {
                      handleClose();
                      props.onEditIconClicked(props);
                    }}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <EditIcon htmlColor="rgba(69, 20, 155, 1)" />
                    </ListItemIcon>
                    <ListItemText primary="Edit" />
                  </ListItem>}

                  {/* Delete Icon */}
                  {!props?.hideDeleteBtn && <ListItem
                    dense
                    button
                    onClick={() => {
                      handleClose();
                      props.onDeleteIconClicked(props);
                    }}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <Delete htmlColor="rgba(69, 20, 155, 1)" />
                    </ListItemIcon>
                    <ListItemText primary="Delete" />
                  </ListItem>}

                </MUList>
              </Popover>
            </div>
          )}

        </Grid>
      )
      }

    </Grid >
  )
}

CardList.propTypes = {
  type: PropTypes.oneOf(['1', '2', '3', '4', '5']),

  //type 3 and type 4 props
  onCardClicked: PropTypes.func,

  //type 2 props
  for_verification_count: PropTypes.number,
  verified_count: PropTypes.number,

  //type 3 props
  for_investigation_count: PropTypes.number,
  investigated_count: PropTypes.number,
  archived_count: PropTypes.number,

  //type 5 props
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
  count_name: PropTypes.string,
  onCountClicked: PropTypes.func,
  actionBtnName: PropTypes.string,
  onActionBtnClicked: PropTypes.func,
  isMoreBtn: PropTypes.bool,
  onEditIconClicked: PropTypes.func,
  onDeleteIconClicked: PropTypes.func,
  hideActionBtn: PropTypes.bool,
  hideAvatar: PropTypes.bool,
  show_card: PropTypes.bool,
  card_title: PropTypes.string,
  show_sub_title: PropTypes.string,
  sub_title: PropTypes.string,
  hideCountButton: PropTypes.bool,

  showManageLocationButton: PropTypes.bool,
  manageLocationBtnName: PropTypes.string,
  manageLocationBtnAction: PropTypes.func,


  //type 6 props

  //already declared in proptypes five

  // src: PropTypes.string,
  // alt: PropTypes.string,
  // title: PropTypes.string,
  // isMoreBtn: PropTypes.bool,
  // show_card: PropTypes.bool,
  // card_title: PropTypes.string,
  // hideAvatar: PropTypes.bool,

  show_sub_card: PropTypes.bool,
  sub_card_title: PropTypes.string,
  divider: PropTypes.bool,
  email_id: PropTypes.string,
  switch: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  privilegesButton: PropTypes.bool,
  privilegesButtonAction: PropTypes.func,
  privilegesButtonName: PropTypes.string,
  dataSecurityButton: PropTypes.bool,
  dataSecurityButtonAction: PropTypes.func,
  dataSecurityButtonName: PropTypes.string,
  viewUserButton: PropTypes.bool,
  viewUserButtonAction: PropTypes.func,
  viewUserButtonName: PropTypes.string,
  mapRoleButton: PropTypes.bool,
  mapRoleButtonAction: PropTypes.string,
  mapRoleButtonName: PropTypes.string,


}

CardList.defaultProps = {
  type: '1',

  count: 0
}
