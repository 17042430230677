import React from 'react'
import {
    Button,
    makeStyles,
    Divider,
    Typography,
    Grid
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField'
import { BackdropContext, AlertContext } from '../../contexts'
import { useMutation } from 'react-apollo';
import { Message, AlertProps } from '../../utils'
import mutations from '../../graphql/mutations';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh'
    },
    header: {
        padding: '10px 20px'
    },
    formNameParent: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    btnParent: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}))

export const AddEditForm = props => {
    const classes = useStyles()

    const [state, setState] = React.useState({
        name: props?.editData?.formName ?? "",
        id: props?.editData?.id ?? "",
        error: {
            name: false,
            type_id: false
        }
    })

    const backdrop = React.useContext(BackdropContext);

    const alert = React.useContext(AlertContext);

    const [UPSERT_FORM, upsertForm] = useMutation(props?.isEdit ? mutations.editForm : mutations.addForm);

    const onChangeInput = e => {
        setState({ ...state, name: e.target.value })
    }

    const validation = () => {
        if (state?.name?.length === 0) {
            state.error = {
                name: state?.name?.length === 0 ? true : false
            }
            setState({ ...state })
        } else {
            return true
        }
    }

    const submitForm = () => {
        const isValidated = validation()

        if (!isValidated) {
            return false
        }

        let variables = {
            formName: state?.name,
        }

        if (props.isEdit) {
            variables.formId = state?.id;
            variables.isActive = true;
        }

        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: props.isEdit ? Message.manage_form_update : Message.manage_form_add
        });
        
        UPSERT_FORM({
            variables
        }).then(res => {
            backdrop.setBackDrop({ ...backdrop, open: false })
            props.onActionCompleted(props.isEdit)
            showSuccessErrorAlert(res);
        }).catch(errors => {
            showSuccessErrorAlert({ errors })
        })
    }

    const showSuccessErrorAlert = (res) => {
        let resStatus = res.errors;
        let statusMessage = props.isEdit ? "Form Updated Successfully !" : "Form Added Successfully !";
        let statusMessage2 = props.isEdit ? "Form Updated Failed!" : "Form Adding Failed !";
        alert.setSnack({
            ...alert,
            open: true,
            severity: resStatus ? AlertProps.severity.error : AlertProps.severity.success,
            msg: resStatus ? statusMessage2 : statusMessage,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center
        })
    }

    return (
        <div className={classes.root}>
            <Grid
                container
                direction='column'
                justify='flex-start'
                alignItems='stretch'
            >
                <Grid item>
                    <Typography className={classes.header}>
                        {props.isEdit ? 'Update Form' : 'Add Form'}
                    </Typography>
                    <Divider />
                </Grid>

                <Grid item className={classes.formNameParent}>
                    <TextField
                        onChange={e => onChangeInput(e)}
                        value={state?.name ?? ''}
                        fullWidth
                        label='Form Name'
                        id='outlined-size-small'
                        variant='outlined'
                        size='small'
                        error={state.error.name}
                        helperText={
                            state.error.name === true ? Message.requiredMessage : ''
                        }
                    />
                </Grid>

                <Grid item className={classes.btnParent}>
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={upsertForm?.loading}
                        onClick={() => submitForm()}
                    >
                        {props.isEdit
                            ? upsertForm?.loading
                                ? 'Updating...'
                                : 'Update Form'
                            : upsertForm?.loading
                                ? 'Adding...'
                                : 'Add Form'}
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}
