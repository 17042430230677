import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Divider, Button, TextField } from "@material-ui/core";
import { LoadingSection, NotFoundSection } from "../../components";
import { Message, AlertProps } from "../../utils";
import { AlertContext } from '../../contexts';
import { useLazyQuery, useMutation } from 'react-apollo'
import query from "../../graphql/queries";
import mutations from "../../graphql/mutations";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
    search: {
        position: "sticky",
        zIndex: 1,
        top: "-1px",
        padding: "8px 8px 0px 8px",
    },
    header: {
        padding: "10px 20px",
    },
    listArea: {
        padding: "10px 20px",
        overflow: "auto",
        height: "calc(100vh - 145px)",
    },
    list: {
        padding: "4px 4px",
        borderBottom: "1.4px solid #9e9e9e40",
    },
    buttonText: {
        fontSize: "12px",
        padding: 0,
        textTransform: "capitalize",
    },
    buttonTextAlter: {
        fontSize: "12px",
        padding: 0,
        textTransform: "capitalize",
        color: "red",
    },
}));

export const MapUnmapForms = (props) => {

    const classes = useStyles();
    const alert = React.useContext(AlertContext);
    const [formId, setformId] = useState(null);
    const [otherFormId, setOtherFormId] = useState(null);

    const [GET_ALL_REQUEST_TYPES, listAllFormsPlusMapped] = useLazyQuery(query.getMasterRequestTypes());
    const [TAG_UNTAG_FORM] = useMutation(mutations.tagUntagForm);

    const listAllFormsPlusMappedFunc = (searchKey) => {
        GET_ALL_REQUEST_TYPES({
            variables: { searchKey },
            fetchPolicy: "no-cache"
        });
    };

    React.useLayoutEffect(() => {
        const intial = async () => {
            if (props?.data?.id) {
                GET_ALL_REQUEST_TYPES({
                    variables: {
                        searchKey: "",
                    },
                    fetchPolicy: "no-cache"
                })
            }
        };

        intial();
        // eslint-disable-next-line
    }, [props?.data?.id]);

    const searchForms = (value) => {
        if (value && value?.length % 3 === 0) {
            listAllFormsPlusMappedFunc(value);
        } else if (!value) {
            listAllFormsPlusMappedFunc(value);
        }
    };

    const tagUntagForms = (el) => {
        if (el.e) {
            let variables = {
                id: el.id,
            };

            if (el.isMyself) {
                setformId(variables.id);
                variables.form = el.is_mapped ? props?.data?.id : null;
                variables.othersForm = el.othersForm;
            } else {
                setOtherFormId(variables.id);
                variables.othersForm = el.is_mapped ? props?.data?.id : null;
                variables.form = el.form;
            }

            TAG_UNTAG_FORM({
                variables
            }).then(res => {

                if (el.isMyself) {
                    setformId(null);
                } else {
                    setOtherFormId(null);
                }

                listAllFormsPlusMapped.refetch();

                let resStatus = res.errors;
                let statusMessage = el.is_mapped ? "Form Taged Successfully !" : "Form UnTaged Successfully !";
                let statusMessage2 = el.is_mapped ? "Form Taging Failed!" : "Form Untaging Failed !";
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: resStatus ? AlertProps.severity.error : AlertProps.severity.success,
                    msg: resStatus ? statusMessage2 : statusMessage,
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            }).catch(error => {
                let statusMessage = el.is_mapped ? "Form Taging Failed!" : "Form Untaging Failed !";
                if (el.isMyself) {
                    setformId(null);
                } else {
                    setOtherFormId(null);
                }
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: statusMessage,
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            })
        }
    };

    return (
        <Grid>
            <Typography className={classes.header}>{`Tag Forms : ${props?.data?.formName} `}</Typography>
            <Divider />
            <div className={classes.search}>
                <TextField
                    variant="outlined"
                    placeholder="Search"
                    size="small"
                    fullWidth
                    onChange={(e) => searchForms(e.target.value)}
                />
            </div>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Request Type</TableCell>
                        <TableCell width="72px" align="center">Myself</TableCell>
                        <TableCell width="72px" align="center">Others</TableCell>
                    </TableRow>
                </TableHead>
                {listAllFormsPlusMapped?.loading && listAllFormsPlusMapped.data?.allMasterRequestTypes?.nodes?.length === 0 && (
                    <Grid item className={classes.listArea}>
                        <LoadingSection message="Fetching Data..." />
                    </Grid>
                )}
                {listAllFormsPlusMapped?.data?.allMasterRequestTypes?.nodes?.length > 0 ? (
                    <TableBody >
                        { listAllFormsPlusMapped?.data?.allMasterRequestTypes?.nodes.map((val, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell align="left">
                                        {val?.requestType}
                                    </TableCell>
                                    <TableCell align="left">
                                        <Button
                                            size={"small"}
                                            id={val?.form_id + "_myself"}
                                            variant={"text"}
                                            color={"secondary"}
                                            className={
                                                val?.mappedForm?.id === props?.data?.id
                                                    ? classes.buttonTextAlter
                                                    : classes.buttonText
                                            }
                                            disabled={formId === val?.id}
                                            onClick={(e) =>
                                                tagUntagForms({
                                                    e,
                                                    is_mapped: !val?.mappedForm?.id,
                                                    id: val?.id,
                                                    form: val?.form,
                                                    othersForm: val?.othersForm,
                                                    isMyself: true,
                                                })
                                            }
                                        >
                                            {val?.mappedForm?.id === props?.data?.id ?
                                                formId === val?.id
                                                    ? `Untagging...`
                                                    : "Untag"
                                                : formId === val?.id
                                                    ? `Tagging...`
                                                    : "Tag"
                                            }
                                        </Button>
                                    </TableCell>

                                    <TableCell align="left">
                                        <Button
                                            size={"small"}
                                            id={val?.form_id + "_other"}
                                            variant={"text"}
                                            color={"secondary"}
                                            className={
                                                val?.otherMappedForm?.id === props?.data?.id
                                                    ? classes.buttonTextAlter
                                                    : classes.buttonText
                                            }
                                            disabled={otherFormId === val?.id}
                                            onClick={(e) =>
                                                tagUntagForms({
                                                    e,
                                                    is_mapped: !val?.otherMappedForm?.id,
                                                    id: val?.id,
                                                    form: val?.form,
                                                    othersForm: val?.othersForm,
                                                    isMyself: false,
                                                })
                                            }
                                        >
                                            {val?.otherMappedForm?.id === props?.data?.id ?
                                                otherFormId === val?.id
                                                    ? `Untagging...`
                                                    : "Untag"
                                                : otherFormId === val?.id
                                                    ? `Tagging...`
                                                    : "Tag"
                                            }
                                        </Button>
                                    </TableCell>

                                </TableRow>
                            );
                        })}
                    </TableBody>
                ) : (
                    <>
                        {
                            listAllFormsPlusMapped?.data?.length === 0
                            && <NotFoundSection message={Message.forms_empty} />
                        }
                    </>
                )}
            </Table>
        </Grid>
    );
}

MapUnmapForms.defaultProps = {
    onClickAction: () => { },
};
