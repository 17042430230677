import React from 'react';
import { Routes } from '../router/routes';
// import ListAltIcon from '@material-ui/icons/ListAlt';
import ViewStreamIcon from '@material-ui/icons/ViewStream';


/**
 * Object with role as key and value, which is used for 
 * comparison of role in different place.
 */
export const UserRoles = {
    role: "role"
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
    "role": "Role"
};

/**
 * Object which has the different themes used in 
 * the application.
 */
export let Themes = {
    default: "default",
    dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert) 
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
    vertical: {
        top: "top",
        bottom: "bottom",
    },
    horizontal: {
        left: "left",
        right: "right",
        center: "center",
    },
    severity: {
        success: "success",
        error: "error",
        warning: "warning",
        info: "info",
    },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js) 
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
    direction: {
        top: "top",
        bottom: "bottom",
        left: "left",
        right: "right",
    },
    variant: {
        permanent: "permanent",
        persistent: "persistent",
        temporary: "temporary",
    },
};

/**
 * Object has the key and value pair of all the keys which 
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
    authToken: "auth_token",
    version: "version"
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
    get: "GET",
    post: "POST",
    put: "PUT",
    delete: "DELETE",
    update: "UPDATE",
};

export const masterTable = {
    requestTypes: "requestTypes",
    commentStatus: "commentStatus",
    requestCloseStatus: "requestCloseStatus",
    responseTypes: "responseTypes",
    requestStatuses: "requestStatuses",
}


export const NavBarArr = [
    {
        name: "Master Management",
        link: Routes.master_management,
        icon: <ViewStreamIcon />
    },
    {
        name: "Manage Forms",
        link: Routes.manage_forms,
        icon: <ViewStreamIcon />
    }
]

export const FormComponents = {
    "single_tab_select": "single_tab_select",
    "multi_tab_select": "multi_tab_select",
    "input_text": "input_text",
    "input_textarea": "input_textarea",
    "input_date_picker": "input_date_picker",
    "multi_select_tag": "multi_select_tag",
    "custom_multi_select_tag": "custom_multi_select_tag",
    "select_modal_dropdown": "select_modal_dropdown",
    "list_text": "list_text",
    "input_number": "input_number",
    "hours_minutes_range": "hours_minutes_range",
    "grade": "grade",
    "image_picker": "image_picker",
    "switch_tab": "switch_tab",
    "range_picker": "range_picker",
    "popup_switch": "popup_switch",
    "label": "label",
    "note": "note",
    "check_box": "check_box",
    "radio_button": "radio_button",
    "location": 'location',
    'custom_table': 'custom_table',
    'custom_table_2': 'custom_table_2'
}


export const DependencyType = [
    {
        value: "form",
        label: "Form"
    },
    {
        value: "section",
        label: "Sections"
    },
    {
        value: "question",
        label: "Question"
    }
]

export const FORM_COMPONENT_TYPES = [
    {
        "_key": "1",
        "_id": "master_component_types/1",
        "_rev": "_b38l73O---",
        "component_type_id": "1",
        "component_value": "single_tab_select",
        "value": "single_tab_select",
        "component_name": "Choose an option - Tap",
        "label": "Choose an option - Tap",
        "is_active": true
    },
    {
        "_key": "2",
        "_id": "master_component_types/2",
        "_rev": "_b38ma46---",
        "component_type_id": "2",
        "component_value": "multi_tab_select",
        "value": "multi_tab_select",
        "component_name": "Choose multiple options - Tap",
        "label": "Choose multiple options - Tap",
        "is_active": true
    },
    {
        "_key": "3",
        "_id": "master_component_types/3",
        "_rev": "_b38moqy---",
        "component_type_id": "3",
        "component_value": "input_text",
        "value": "input_text",
        "component_name": "Add short answer",
        "label": "Add short answer",
        "is_active": true
    },
    {
        "_key": "4",
        "_id": "master_component_types/4",
        "_rev": "_b38nIRG---",
        "component_type_id": "4",
        "component_value": "input_textarea",
        "value": "input_textarea",
        "component_name": "Add paragraph",
        "label": "Add paragraph",
        "is_active": true
    },
    {
        "_key": "5",
        "_id": "master_component_types/5",
        "_rev": "_b38nRhS---",
        "component_type_id": "5",
        "component_value": "input_date_picker",
        "value": "input_date_picker",
        "component_name": "Choose date",
        "label": "Choose date",
        "is_active": true
    },
    {
        "_key": "6",
        "_id": "master_component_types/6",
        "_rev": "_b38nZVu---",
        "component_type_id": "6",
        "component_value": "multi_select_tag",
        "value": "multi_select_tag",
        "component_name": "Choose multiple tags from given options",
        "label": "Choose multiple tags from given options",
        "is_active": true
    },
    {
        "_key": "7",
        "_id": "master_component_types/7",
        "_rev": "_b38ngwa---",
        "component_type_id": "7",
        "component_value": "custom_multi_select_tag",
        "value": "custom_multi_select_tag",
        "component_name": "Add multiple tags on own",
        "label": "Add multiple tags on own",
        "is_active": true
    },
    {
        "_key": "8",
        "_id": "master_component_types/8",
        "_rev": "_b38nsGO---",
        "component_type_id": "8",
        "component_value": "select_modal_dropdown",
        "value": "select_modal_dropdown",
        "component_name": "Choose an option from drop down",
        "label": "Choose an option from drop down",
        "is_active": true
    },
    {
        "_key": "10",
        "_id": "master_component_types/10",
        "_rev": "_b38n9Ou---",
        "component_type_id": "10",
        "component_value": "input_number",
        "value": "input_number",
        "component_name": "Add a numeric input",
        "label": "Add a numeric input",
        "is_active": true
    },
    {
        "_key": "16",
        "_id": "master_component_types/16",
        "_rev": "_b38oydm---",
        "component_type_id": "16",
        "component_value": "popup_switch",
        "value": "popup_switch",
        "component_name": "Smart skip",
        "label": "Smart skip",
        "is_active": true
    },
    {
        "_key": "17",
        "_id": "master_component_types/17",
        "_rev": "_b38o6TK---",
        "component_type_id": "17",
        "component_value": "label",
        "value": "label",
        "component_name": "Show a label",
        "label": "Show a label",
        "is_active": true
    },
    {
        "_key": "18",
        "_id": "master_component_types/18",
        "_rev": "_b38pEn2---",
        "component_type_id": "18",
        "component_value": "note",
        "value": "note",
        "component_name": "Show a note",
        "label": "Show a note",
        "is_active": true
    },
    {
        "_key": "9",
        "_id": "master_component_types/9",
        "_rev": "_b4ZBvSW---",
        "component_type_id": "9",
        "component_value": "list_text",
        "value": "list_text",
        "component_name": "Create a list",
        "label": "Create a list",
        "is_active": false
    },
    {
        "_key": "11",
        "_id": "master_component_types/11",
        "_rev": "_b4ZCV86---",
        "component_type_id": "11",
        "component_value": "hours_minutes_range",
        "value": "hours_minutes_range",
        "component_name": "Choose hours and minutes",
        "label": "Choose hours and minutes",
        "is_active": false
    },
    {
        "_key": "12",
        "_id": "master_component_types/12",
        "_rev": "_b4ZCtKC---",
        "component_type_id": "12",
        "component_value": "grade",
        "value": "grade",
        "component_name": "Choose grade",
        "label": "Choose grade",
        "is_active": false
    },
    {
        "_key": "13",
        "_id": "master_component_types/13",
        "_rev": "_b4ZGLpG---",
        "component_type_id": "13",
        "component_value": "image_picker",
        "value": "image_picker",
        "component_name": "Upload an image",
        "label": "Upload an image",
        "is_active": false
    },
    {
        "_key": "14",
        "_id": "master_component_types/14",
        "_rev": "_b4ZGVbG---",
        "component_type_id": "14",
        "component_value": "switch_tab",
        "value": "switch_tab",
        "component_name": "Choose an option - Switch",
        "label": "Choose an option - Switch",
        "is_active": false
    },
    {
        "_key": "15",
        "_id": "master_component_types/15",
        "_rev": "_b4ZGh4W---",
        "component_type_id": "15",
        "component_value": "range_picker",
        "value": "range_picker",
        "component_name": "Choose a range",
        "label": "Choose a range",
        "is_active": false
    },
    {
        "_key": "19",
        "_id": "master_component_types/19",
        "_rev": "_cD4y_Eu---",
        "component_type_id": "19",
        "component_value": "check_box",
        "value": "check_box",
        "component_name": "Add a Check box",
        "label": "Add a Check box",
        "is_active": true
    },
    {
        "_key": "20",
        "_id": "master_component_types/20",
        "_rev": "_cD7FgPi---",
        "component_type_id": "20",
        "component_value": "radio_button",
        "value": "radio_button",
        "component_name": "Add a Radio Button",
        "label": "Add a Radio Button",
        "is_active": true
    },
    {
        "_key": "21",
        "_id": "master_component_types/21",
        "_rev": "_cD751L----",
        "component_type_id": "21",
        "component_value": "location",
        "value": "location",
        "component_name": "Add a location",
        "label": "Add a location",
        "is_active": true
    },
    {
        "_key": "22",
        "_id": "master_component_types/22",
        "_rev": "_cIpCvBq---",
        "component_type_id": "22",
        "component_value": "custom_table",
        "value": "custom_table",
        "component_name": "Select Table Type 1",
        "label": "Select Table Type 1",
        "is_active": true
    },
    {
        "_key": "23",
        "_id": "master_component_types/23",
        "_rev": "_cIsnV5a---",
        "component_type_id": "23",
        "component_value": "custom_table_2",
        "value": "custom_table_2",
        "component_name": "Select Table Type 2",
        "label": "Select Table Type 2",
        "is_active": true
    },
    {
        "_key": "24",
        "_id": "master_component_types/24",
        "_rev": "_cSuSVJW---",
        "component_type_id": "24",
        "component_value": "mobile_no",
        "value": "mobile_no",
        "component_name": "Mobile No",
        "label": "Mobile No",
        "is_active": true
    }
]