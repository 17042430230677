import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from "react-router-dom";
import { Routes } from "./routes";

import {
  NotFound,
  MasterManagement,
  ManageForms,
  FormConfigurator
} from './../screens';

const RouterApp = (props) => {

  return (
    <Router>
      <Switch>
        {/* form component list */}
        <Route exact path={Routes.home} component={MasterManagement} />

        <Route exact path={Routes.master_management} component={MasterManagement} />

        <Route exact path={Routes.manage_forms} component={ManageForms} />

        <Route exact path={Routes.forms} component={FormConfigurator} />


        {/* For unknow/non-defined path */}
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
