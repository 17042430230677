//Normal messagess
export let Message = {
  requiredMessage: "This field is required",

  //Manage Forms Messages
  manage_form_fetching: "Fetching the forms...",
  manage_form_not_found: "Well! You don't have any forms to show, Please Add one.",
  manage_form_error: "Opps! Something went wrong, Try Again",

  manage_form_add: "Addding New Form...",
  manage_form_update: "Updating Form Details...",
  manage_form_delete: "Deleting Form...",

  manage_form_delete_title: "Are you sure you want to delete the form?",
  manage_form_delete_message: "If you delete this form all the Sections and Forms tagged to this form will be deleted.",
  manage_form_delete_btn: "I'm aware delete",

  //Section Message
  section_add: (sec) => `Addding New ${sec}...`,
  section_update: (sec) => `Updating ${sec} details...`,
  section_delete: (sec) => `Deleting ${sec}...`,

  section_delete_title: "Are you sure?",
  section_delete_message: "If you delete this section/form all the forms/questions mapped to this section/form will be removed.",
  section_delete_btn: "I'm aware delete",

};
