import { gql } from "apollo-boost";

const query = {
    getAllFroms: (params) => gql`
        query listForms {
            forms: allMasterForms(condition:{isActive:true}) {
            data: nodes {
                id
                formName
                formJson
                isActive
                createdAt
                updatedAt
            }
            }
        }
    `,

    getFormById: (params) => gql`
        query formDetailById($id: Int!) {
            form_detail: masterFormById(id: $id) {
                formName
                id
                isActive
                forms:sectionsByFormId(orderBy: PRIORITY_ASC, condition: {isForm: true,isActive: true}) {
                    data:nodes {
                      id
                      sectionName
                      isForm
                      priority
                    }
                }
            }
        }      
    `,
    getMasterRequestTypes : () => {
        return gql`
        query getAllRequestTypes($searchKey: String) {
            allMasterRequestTypes(
              condition: {isActive: true}
              filter: {requestType: {includesInsensitive: $searchKey}}
            ) {
              nodes {
                id
                requestType
                form
                othersForm
                otherMappedForm: masterFormByOthersForm {
                  id
                  formName
                }
                mappedForm: masterFormByForm {
                  id
                  formName
                }
              }
            }
          }
            
        `
    }
};

export default query;
