import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { uuid } from 'uuidv4';
import {
  Typography,
  Divider,
  Button,
  Popover,
  TextField,
  Grid,
  LinearProgress,
  List as MUList,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import Skeleton from '@material-ui/lab/Skeleton';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import { Delete } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { AlertContext, DialogContext, BackdropContext } from '../../contexts';
import { AlertProps, Message } from '../../utils';
import { useMutation } from "react-apollo";
import mutations from "../../graphql/mutations";

const categoryData = [
  {
    name: "Section",
  },
  {
    name: "Form",
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100vh - 130px)',
    flexGrow: 1,
    background: theme.palette.background.paper,
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
    // overflowY:'auto'
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(1),
  },
  headRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    justifyContent: "space-between",
  },
  stickyHeader: {
    top: 0,
    zIndex: 2,
    position: 'sticky',
    background: '#fff'
  },
  menuList: {
    padding: theme.spacing(1),
    height: 'calc(100vh - 180px)',
    overflowY: 'auto'
  },
  selectedTab: {
    backgroundColor: theme.palette.secondary.main + " !important",
    color: "#fff !important",
    border: `1px solid ${theme.palette.secondary.light} !important`,
  },
  selecteTab: {
    margin: "10px 16px 0px 0px",
    borderRadius: "6px !important",
    border: "1px solid rgba(0, 0, 0, 0.12) !important",
  },
  addNewSection: {
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f27d350f"
    }
  },
  labelText: {
    overflowWrap: "anywhere"
  },
  addSectionButton: {
    width: "20px",
    textAlign: "center",
    paddingTop: "5px",
    "&:hover": {
      background: "rgba(242, 125, 53, 0.04)",
    }
  },
  moreButton: {
    paddingTop: "5px",
    "&:hover": {
      background: "rgba(242, 125, 53, 0.04)",
    }
  },
  activeTreeSection: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    justifyContent: "space-between",
    borderRadius: theme.spacing(1),
    background: "#EDE2FF",
    borderLeft: '3px solid ' + theme.palette.primary.light,
  },
  treeItem: {
    '& .MuiTreeItem-label': {
      borderRadius: theme.spacing(1),
      paddingLeft: 0,
    },
    '& .MuiTreeItem-content': {
      marginTop: theme.spacing(0.5),
    }
  },
}));

const initialSectionFormData = {
  name: "",
  type: null,
  sectionType: 0,
  showSectionType: false,
  data: null,
  anchorEl: null,
  level: 1,
  top: 0,
  left: 0,
  editAnchorEl: null,
  editTop: 0,
  editLeft: 0,
  editData: null,
  deleteModal: false
}

export default function FileSystemNavigator(props) {

  const classes = useStyles();

  const [formTreeViewData, setFormTreeViewData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [state, setState] = React.useState(initialSectionFormData);

  const alert = React.useContext(AlertContext);
  const dialog = React.useContext(DialogContext);
  const backDrop = React.useContext(BackdropContext);

  const [UPSERT_SECTION, upsertSection] = useMutation(state.editData ? mutations.editSection : mutations.addSection);
  const [DELETE_SECTION] = useMutation(mutations.editSection);

  const initialDataFetch = async () => {
    setLoading(true)
    props.getFormDetail()
  }

  useEffect(() => {
    const initialDataFetch = async () => {
      setFormTreeViewData(props.data)
      setLoading(false);
    }
    initialDataFetch();
  }, [props.data])

  const handleClick = (event, menu, showSectionType = false) => {
    setState({
      ...state,
      data: menu,
      anchorEl: event.currentTarget,
      level: menu?.level ?? 1,
      top: event.clientY,
      left: event.clientX,
      showSectionType
    });
  };

  const upsetSection = () => {
    let variables = {};
    if (state.editData) {

      variables = {
        id: state?.editData?.id,
        sectionName: state?.name,
        isForm: state?.type?.toLocaleLowerCase() === "form" ? true : false,
      }

    } else {

      const CheckPriority = () => {
        let priority = 0;
        if (Object.keys(state.data).length > 0 && state.data.constructor === Object) {
          if (state?.data?.child_sections?.length > 0) {
            return priority = state?.data?.child_sections?.[state?.data?.child_sections?.length - 1].priority ?? 0;
          } else {
            return priority
          }
        } else {
          return priority = formTreeViewData[formTreeViewData.length - 1]?.priority ?? 0;
        }
      }

      variables = {
        "id": uuid(),
        "formId": parseInt(props.id),
        "priority": CheckPriority() + 1,
        "sectionName": state.name,
        "isForm": state?.type?.toLocaleLowerCase() === "form" ? true : false,
        "parentSection": state?.data?.id ?? null,
      }
    }

    UPSERT_SECTION({
      variables
    }).then((res) => {
      if (res.errors) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: 'Something went wrong, Unable to Update',
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: 'Changes updated successfully',
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
        initialDataFetch();
        handleClose();
      }
    }).catch(error => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: 'Something went wrong, Unable to Update',
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center
      })
    });

  }

  const handleClose = () => {
    setState(initialSectionFormData);
  };

  const onClickSection = (data, parentSection) => {
    if (data.isForm) {
      props.getSectionId(data, parentSection);
    }
  }

  const Children = ({ children, Parentindex, parentSection }) => {
    let nestedChildrens = "";
    if (children.child_sections && children.child_sections.length > 0) {
      nestedChildrens = (children.child_sections || []).map((child, index) => {
        return (
          <Children
            key={index}
            index={index}
            Parentindex={`${Parentindex}_${index}`}
            children={child}
            parentSection={children}
            type="child"
          />
        );
      });
    }
    return (
      <>
        <TreeItem
          className={classes.treeItem}
          key={Parentindex}
          nodeId={`${Parentindex}`}
          label={label_type(children)}
          onClick={(e) => onClickSection(children, parentSection)}
        >
          {nestedChildrens}
        </TreeItem>
      </>
    );
  };


  const label_type = (menu) => {

    const type = menu?.isForm ? "Form" : "Section";

    return (
      <div className={props.selected_section_id === menu.id ? classes.activeTreeSection : classes.labelRoot}>
        <Typography variant={"body2"} className={classes.labelText}>
          {menu?.sectionName + " (" + type + ")"}
        </Typography>


        <Typography style={{ display: "flex" }}>
          {type.toLocaleLowerCase() === "section" && (
            <Typography
              className={classes.addSectionButton}
              variant="caption"
              color={"secondary"}
              onClick={(e) => handleClick(e, menu)}
            >
              {"+"}
            </Typography>
          )}
          <Typography onClick={(e) => moreMenuHandleClick(e, menu)} color={"textSecondary"} className={classes.moreButton}>
            <MoreVertRoundedIcon fontSize={"small"} />
          </Typography>
        </Typography>
      </div>
    )
  }


  const onChangeSwitch = (e, newAlignment) => {
    setState({ ...state, type: newAlignment })
  }

  const onChangeName = (e) => {
    setState({ ...state, name: e.target.value })
  }

  const open = Boolean(state.anchorEl);
  const id = open ? "simple-popover" : undefined;


  const moreMenuHandleClick = (event, data) => {

    setState({
      ...state,
      editAnchorEl: event.currentTarget,
      editTop: event.clientY,
      editLeft: event.clientX + 60,
      editData: data,
      name: data.sectionName,
      type: data.isForm ? "Form" : "Section",
      showSectionType: data.level === 1
    });
  };

  const moreMenuHandleClose = () => {
    setState(initialSectionFormData)
  };

  const onEditIconClicked = (event) => {
    setState({
      ...state,
      editAnchorEl: null,
      editTop: 0,
      editLeft: 0,
      anchorEl: event.currentTarget,
      top: event.clientY - 20,
      left: event.clientX
    })
  }

  const deleteSection = (event) => {

    let variables = {
      id: state?.editData?.id,
      formId: parseInt(props.id),
      isActive: false,
      isEdit: true
    }

    dialog.setDialog({ ...dialog, open: false });
    backDrop.setBackDrop({
      ...backDrop,
      open: true,
      message: Message.section_delete(state?.type)
    })

    DELETE_SECTION({
      variables
    }).then((res) => {
      if (res.errors) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: 'Something went wrong, Unable to Delete',
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: state?.type + ' deleted successfully',
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      }
      backDrop.setBackDrop({
        ...backDrop,
        open: false,
      })
      initialDataFetch();
      handleClose();
    }).catch(error => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: 'Something went wrong, Unable to Delete',
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center
      })
      backDrop.setBackDrop({
        ...backDrop,
        open: false,
      })
      initialDataFetch();
      handleClose();
    });

    // Promise.resolve(dispatch(UPSERT_SECTION(params))).then((res) => {
    //   alert.setSnack({
    //     ...alert,
    //     open: true,
    //     severity: AlertProps.severity.success,
    //     msg: state?.type + ' deleted successfully',
    //     vertical: AlertProps.vertical.top,
    //     horizontal: AlertProps.horizontal.center
    //   })
    //   backDrop.setBackDrop({
    //     ...backDrop,
    //     open: false,
    //   })
    //   initialDataFetch();
    //   handleClose();
    // });

  }

  const deleteSectionModal = () => {
    dialog.setDialog({
      ...dialog,
      open: true,
      title: Message.section_delete_title,
      body: Message.section_delete_message,
      positiveBtn: Message.section_delete_btn,
      onOk: () => deleteSection(),
    })
  }

  const moreOpen = Boolean(state.editAnchorEl);
  const moreId = open ? 'simple-popover' : undefined;


  return (
    <div className={classes.root}>
      <div className={classes.stickyHeader}>
        <div className={classes.headRoot}>
          <Typography variant={"body1"}>
            {"Form Structure / Tree "}
          </Typography>
          <Button
            size="small"
            color={"secondary"}
            aria-describedby={id}
            onClick={(e) => handleClick(e, {}, true)}
          >
            {"+ Add"}
          </Button>
        </div>

        <Divider />

        {loading && <LinearProgress />}
      </div>

      <div className={classes.menuList}>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon color={"primary"} />}
          defaultExpandIcon={<ChevronRightIcon color={"primary"} />}
        >
          {formTreeViewData?.length > 0 ? formTreeViewData.map((val, Parentindex) => {
            return (
              <TreeItem
                className={classes.treeItem}
                key={Parentindex}
                nodeId={`${Parentindex}`}
                label={label_type(val)}
                onClick={(e) => onClickSection(val, val)}
              >
                {val?.child_sections?.length > 0 &&
                  val?.child_sections?.map((children, index) => {
                    return (
                      <Children
                        key={index}
                        index={index}
                        Parentindex={`${Parentindex}_${index}`}
                        children={children}
                        parentSection={val}
                      />
                    );
                  })}
              </TreeItem>
            );
          }) :
            loading ?
              <>
                <Typography variant={"h5"}><Skeleton /></Typography>
                <Typography variant={"h5"}><Skeleton /></Typography>
                <Typography variant={"h5"}><Skeleton /></Typography>
              </>
              :
              <Typography color={"textSecondary"} className={classes.addNewSection} onClick={(e) => handleClick(e, {})}>
                {"+ Add new section"}
              </Typography>
          }
        </TreeView>
      </div>

      {/* create new form poper UI */}
      <Popover
        anchorReference="anchorPosition"
        anchorPosition={{ top: state.top, left: state.left }}
        id={id}
        open={open}
        anchorEl={state.anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography style={{ padding: "10px 20px" }}>{state.editData ? "Edit Section / Form" : "Add New Section / Form"}</Typography>
        <Divider />
        <div style={{ padding: "6px 20px" }}>
          <Grid item xs={12} style={{ margin: "16px 0px" }}>
            <TextField
              value={state.name}
              onChange={onChangeName}
              fullWidth
              label="Name"
              id="outlined-size-small"
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12} style={{ margin: "16px 0px" }}>
            <Typography className={classes.lable} >{"Form Type"}</Typography>
            <ToggleButtonGroup
              size={"small"}
              value={state.type}
              exclusive
              onChange={onChangeSwitch}
              aria-label="text alignment"
            >
              {categoryData.map((name, index) => {
                if (state.level === 3 && index === 0) {
                  return <></>;
                }
                return (
                  <ToggleButton
                    key={index}
                    value={name.name}
                    aria-label="left"
                    classes={{ root: classes.selecteTab, selected: classes.selectedTab }}>
                    <Typography
                      component={"p"}
                      variant={"caption"} >
                      {name.name}
                    </Typography>
                  </ToggleButton>
                )
              })}
            </ToggleButtonGroup>
          </Grid>

        </div>
        <Divider />
        <Grid style={{ padding: "10px", textAlign: "end" }}>
          <Button
            disabled={(state.type && state.name?.length > 0) ? upsertSection?.loading : true}
            variant={"contained"}
            color={"primary"}
            size={"small"}
            onClick={upsetSection} >
            {state.editData ? upsertSection?.loading ? "Updating..." : "Update"
              : upsertSection?.loading ? "Adding..." : "Add"}
          </Button>
        </Grid>
      </Popover>

      {/* {More option popover} */}
      <Popover
        anchorReference="anchorPosition"
        anchorPosition={{ top: state.editTop, left: state.editLeft }}
        id={moreId}
        open={moreOpen}
        anchorEl={state.editAnchorEl}
        onClose={moreMenuHandleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MUList dense>
          {/* Edit Icon */}
          <ListItem dense button onClick={(e) => {
            moreMenuHandleClose();
            onEditIconClicked(e);
          }}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </ListItem>

          {/* Delete Icon */}
          <ListItem dense button onClick={(e) => {
            moreMenuHandleClose();
            deleteSectionModal(e)
          }}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </ListItem>

        </MUList>
      </Popover>
    </div>
  );
}
