import React from 'react';
import { ListForms } from './manageForms';
import { withNavBars } from '../../HOCs';
import { withStyles } from '@material-ui/core';
import { Query } from 'react-apollo';
import query from '../../graphql/queries';

const styles = ((theme) => ({
    root: {

    }
}))

class ManageFormsParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sort: "all"
        }
    }

    handleSortChange = (sort, refetch) => {
        this.setState({
            sort
        }, () => {
            refetch();
        })
    }

    getFormsParams = (searchKey = "", created_at = false) => {
        let sort = {
            sortField: "form_name",
            sortDirection: "ASC"
        };

        if (created_at) {
            sort.sortField = "updated_at";
            sort.sortDirection = "DESC";
        }

        let formTypeId = "";
        if (this.state.sort !== "all") {
            formTypeId = this.state.sort;
        }

        return {
            formName: searchKey.trim(),
            formTypeId,
            ...sort
        }

    };

    render() {

        const { classes } = this.props;

        return <div className={classes.root}>
            <Query query={query.getAllFroms(this.getFormsParams())}>
                {({ loading, error, data, refetch }) => {
                    return <ListForms
                        loading={loading}
                        error={error}
                        data={data?.forms?.data ?? []}
                        getForms={refetch}
                        sort={this.state.sort}
                        handleSortChange={(sort) => this.handleSortChange(sort, refetch)}
                    />
                }}
            </Query>

        </div>
    }
}


export default (withNavBars(
    withStyles(styles)(ManageFormsParent)
));

