import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { DrawerContext, DialogContext, BackdropContext, AlertContext } from '../../contexts';
import { CardList, InternatlServerError, NotFoundSection, SearchFilter, CardListLoader } from '../../components';
import { Message, DrawerProps, AlertProps } from '../../utils';
import { AddEditForm } from './addEditForm';
import { useMutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../router/routes';
import mutations from '../../graphql/mutations';
import { MapUnmapForms } from './mapUnmapForm';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    search: {
        paddingBottom: theme.spacing(2),
        position: "sticky",
        top: 0,
        paddingTop: theme.spacing(2),
        zIndex: theme.zIndex.appBar + 1
    },
    progressBar: {
        position: 'absolute',
        width: '100%'
    }
}));

export const ListForms = props => {

    const classes = useStyles(props);

    const history = useHistory();

    const alert = React.useContext(AlertContext);

    const [UPSERT_FORM] = useMutation(mutations.editForm);

    const drawerContext = React.useContext(DrawerContext);
    const dialogContext = React.useContext(DialogContext);
    const backdrop = React.useContext(BackdropContext);

    let {
        data = [],
        loading,
        error
    } = props;


    const onViewFormClicked = (data) => {
        history.push(Routes.forms_parent + data?.id);
    }

    const onAddFormClicked = () => {
        drawerContext.setDrawer({
            ...drawerContext,
            open: true,
            direction: DrawerProps.direction.right,
            variant: DrawerProps.variant.temporary,
            component: <AddEditForm onActionCompleted={onActionCompleted} />,
            onClose: () => onActionCompleted()
        })
    }

    const onActionCompleted = () => {
        onDrawerClosed();
        props.getForms(document.getElementById("humanchain_search_bar").value, true);
    }

    const onDrawerClosed = () => {
        drawerContext.setDrawer({
            ...drawerContext,
            open: false
        })
    }

    const onEditIconClicked = (data) => {
        drawerContext.setDrawer({
            ...drawerContext,
            open: true,
            direction: DrawerProps.direction.right,
            variant: DrawerProps.variant.temporary,
            component: <AddEditForm isEdit={true} editData={data} onActionCompleted={onActionCompleted} />,
            onClose: () => onDrawerClosed()
        })
    };

    const onDeleteIconClicked = (data) => {
        dialogContext.setDialog({
            ...dialogContext,
            open: true,
            title: Message.manage_form_delete_title,
            body: Message.manage_form_delete_message,
            positiveBtn: Message.manage_form_delete_btn,
            negativeBtn: "Cancel",
            onOk: () => deleteForm(data),
        })
    };

    const onMapBtnClicked = (data) => {
        drawerContext.setDrawer({
            ...drawerContext,
            open: true,
            direction: DrawerProps.direction.right,
            variant: DrawerProps.variant.temporary,
            component: <MapUnmapForms data={data} onActionCompleted={onActionCompleted} />,
            onClose: onClose
        })
    };

    const onClose = () => {
        drawerContext.setDrawer({
            ...drawerContext,
            open: false,
            direction: DrawerProps.direction.right,
            variant: DrawerProps.variant.temporary,
            component: <></>,
        })
    }

    const deleteForm = (data) => {

        dialogContext.setDialog({ ...dialogContext, open: false })
        backdrop.setBackDrop({ ...backdrop, open: true, message: Message.manage_form_delete })

        let variables = {
            formName: data.formName,
            formId: data.id,
            isActive: false
        }

        UPSERT_FORM({
            variables
        }).then(res => {
            backdrop.setBackDrop({ ...backdrop, open: false });
            props.getForms();
            showSuccessErrorMessage(res);
        }).catch(errors => showSuccessErrorMessage({ errors }))
    };

    const showSuccessErrorMessage = (res) => {
        let resStatus = res.errors;
        alert.setSnack({
            ...alert,
            open: true,
            severity: resStatus ? AlertProps.severity.error : AlertProps.severity.success,
            msg: resStatus ? "Form deletion Failed !" : "Form deleted Successfully !",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center
        })
    }

    const onSearchChange = (value) => {
        if (value && value?.length % 3 === 0) {
            props.getForms(value);
        } else if (!value) {
            props.getForms();
        }
    };

    // const sortOptionArray = [
    //     { "value": "all", "label": "All Form Types" },
    //     ...ConvertToReactSelect(formTypes?.data, "form_type_id", "form_type_name")
    // ];

    return (
        <div className={classes.root}>

            {/* Search Filter Button Component */}
            <div className={classes.search}>
                <SearchFilter
                    placeholder="Search Forms"
                    loading={data?.length > 0 ? loading : false}
                    onSearchChange={onSearchChange}
                    buttonName="Add Form"
                    onActionButton={onAddFormClicked}
                    hideFilter={true}
                    hideSearchBar={false}
                    showSort={false}
                    sort={props.sort}
                    // sortOptions={sortOptionArray}
                    handleSortChange={props.handleSortChange}
                    hideButton={false}
                />
            </div>

            {loading && !error && data?.length === 0 && <CardListLoader count={10} />}
            {!loading && error && data?.length === 0 && <InternatlServerError message={Message.manage_form_error} onRetry={props.getForms} />}
            {!loading && !error && data?.length === 0 && <NotFoundSection message={Message.manage_form_not_found} />}

            {data?.length > 0 && <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
            >
                {data?.map((form, key) => (
                    <Grid item key={key}>
                        <CardList
                            type="5"
                            hideAvatar={true}
                            title={form?.formName}
                            show_card={true}
                            card_title={form?.form_type?.form_type_name ?? "Form Type"}
                            hideCountButton={true}
                            actionBtnName={"View Form"}
                            onActionBtnClicked={() => onViewFormClicked(form)}
                            onEditIconClicked={() => onEditIconClicked(form)}
                            onDeleteIconClicked={() => onDeleteIconClicked(form)}
                            hideActionBtn={false}
                            isMoreBtn={true}
                            hideEditBtn={false}
                            hideDeleteBtn={false}
                            hideMapBtn={false}
                            mapBtnName={"Map Form"}
                            onMapBtnClicked={() => onMapBtnClicked(form)}
                        />
                    </Grid>
                ))}
            </Grid>}
        </div>
    )
}
