import { gql } from "apollo-boost";

const mutations = {
  addForm: gql`
    mutation addForm($formName: String!) {
      createMasterForm(
        input: {masterForm: {formName: $formName, createdAt: "${new Date().toISOString()}", updatedAt: "${new Date().toISOString()}", isActive: true}}
      ) {
        masterForm {
          id
          formName
          isActive
          createdAt
          updatedAt
        }
      }
    }
  `,
  editForm: gql`
   mutation editForm($formName: String!, $isActive: Boolean, $formId: Int!) {
    updateMasterFormById(
      input: {masterFormPatch: {formName: $formName, isActive: $isActive, updatedAt: "${new Date().toISOString()}"}, id: $formId}
    ) {
      masterForm {
        id
        formName
        isActive
      }
    }
  }`,
  addSection: gql`
    mutation addSection($id: UUID!, $priority: Int, $isForm: Boolean, $formId: Int, $sectionName: String) {
      createSection(
        input: {section: {id: $id, priority: $priority, isForm: $isForm, formId: $formId, sectionName: $sectionName, isActive: true}}
      ) {
        clientMutationId
      }
    }  
  `,
  editSection: gql`
    mutation editSection($id: UUID!, $sectionName: String, $isForm: Boolean, $isActive: Boolean) {
      updateSectionById(
        input: {sectionPatch: {sectionName: $sectionName, isForm: $isForm, isActive: $isActive}, id: $id}
      ) {
        clientMutationId
      }
    }  
  `,
  tagUntagForm: gql`
  mutation tagUntagForm($id: Int!, $form: Int, $othersForm: Int) {
    updateMasterRequestTypeById(
      input: {masterRequestTypePatch: {form: $form, othersForm: $othersForm }, id: $id}
    ) {
      clientMutationId
    }
  }
  
  `
};

export default mutations;
