import React from 'react';
import { withRouter, Prompt } from "react-router-dom";
import { withStyles, Grid } from '@material-ui/core';
import From from "./formGenerator";
import FormTree from './formTree';
import AgreeDailog from "./agreeDialog";
import { SubHeader } from '../../components';
import { Message } from "../../utils";
import { withAllContexts, withNavBars } from '../../HOCs';
import { withApollo } from 'react-apollo';
import query from '../../graphql/queries';
// import { DrawerContext } from '../../contexts';
// import { DrawerProps } from '../../utils';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  gridArea: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

class FormConfigure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "You are in profile page.",
      section_id: null,
      is_edit: false,
      openAgreeDailog: false,
      skipSectionId: null,
      section_detail_id: null,
      save: false,
      showPreview: false,
      set: false,
      render: false,
      formId: null,
      formTitle: '',
      details: {
        data: [],
        loading: true,
        error: false
      },
      form_detail: {
        data: [],
        loading: true,
        error: false
      },
      formUpdate: {
        data: [],
        loading: false,
        error: false
      }
    };
  }

  onUnload = (e) => {
    e.preventDefault();
    if (this.state.is_edit) {
      e.returnValue = "";
    }
  };

  componentDidMount() {
    this.getFormDetail();
    window.addEventListener("beforeunload", this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  getFormDetail = () => {

    let id = this.props.match.params.id;

    let variables = {
      id: parseInt(id),
    };

    this.props.client?.query({
      query: query.getFormById(),
      fetchPolicy: "no-cache",
      variables
    }).then(res => {
      let details = {
        loading: false,
        error: res.errors,
        data: res.data?.form_detail
      }
      this.setState({
        details
      })
    }).catch(error => {
      let details = {
        loading: false,
        error: true,
        data: error
      }
      this.setState({
        details
      })
    })

    //rahul
    // this.props.GET_RENDER_JSON({ formId: id });
  };

  getSectionId = (data, parentSection) => {
    let section_detail_id = data?.id;
    if (this.state.is_edit) {
      this.setState({
        ...this.state,
        formId: data.id,
        formTitle: data.sectionName,
        section_detail_id,
        skipSectionId: data?.id ?? null,
        openAgreeDailog: true,
        selectedSection: data,
        parentSection: parentSection
      });
    } else {
      this.setState({
        ...this.state,
        formId: data.id,
        formTitle: data.sectionName,
        section_detail_id,
        section_id: data?.id ?? null,
        selectedSection: data,
        parentSection: parentSection
      });
    }
  };

  skipIsedit = (type) => {
    if (type === "error") {
      this.setState({
        ...this.state,
        openAgreeDailog: false,
        save: false,
      });
      return false;
    }
    if (this.state.skipSectionId) {
      this.setState({
        ...this.state,
        openAgreeDailog: false,
        section_id: this.state.skipSectionId,
        skipSectionId: null,
        save: false,
        is_edit: false,
      });
    } else if (this.state.set) {
      this.setState({
        ...this.state,
        openAgreeDailog: false,
        //   skipSectionId: null,
        showPreview: true,
        save: false,
        is_edit: false,
      });
    }
    else {
      this.setState({
        ...this.state,
        openAgreeDailog: false,
        skipSectionId: null,
        save: false,
        is_edit: false,
      });
      setTimeout(() => {
        this.props.history.goBack();
      }, 1000);
    }
  };

  saveToMove = () => {
    this.setState({ ...this.state, save: true, render: true });
  };

  checkFormEdit = (val) => {
    this.setState({ ...this.state, is_edit: val });
  };

  goBack = (data) => {
    if (this.state.is_edit) {
      this.setState({
        ...this.state,
        skipSectionId: data?.id ?? null,
        openAgreeDailog: true,
        set: false,
      });
    } else {
      this.props.history.goBack();
    }
  };

  goPreview = (val) => {

    if (this.state.is_edit) {
      this.setState({
        ...this.state,
        openAgreeDailog: val,
        set: true,
      });
    }
    else {
      this.setState({
        ...this.state,
        showPreview: val,
      });
    }
  };

  onBackBtnClicked = () => {
    this.props.history.goBack();
  };

  drawerContext = this.props.drawer;

  onDrawerClosed = () => {
    this.drawerContext.setDrawer({
      ...this.drawerContext,
      open: false
    })
  }

  render() {

    const { classes } = this.props;

    const { data, loading } = this.state?.details;

    const previewFormDetail = this.state?.form_detail

    return (

      <div className={classes.root}>

        <SubHeader
          title={loading ? Message.form_detail_loading : data?.formName}
          onBackBtnClicked={this.onBackBtnClicked}
        />

        <Grid
          className={classes.gridArea}
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            <FormTree
              selected_section_id={this.state.section_id}
              getSectionId={this.getSectionId}
              id={this?.props?.match?.params?.id ?? null}
              data={data?.forms?.data ?? []}
              getFormDetail={this.getFormDetail}
            />
          </Grid>

          <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
            <From
              handleClose={this.skipIsedit}
              save={this.state.save}
              checkFormEdit={this.checkFormEdit}
              section_id={this?.state?.section_id ?? null}
              section_detail_id={this.state.section_detail_id}
              id={this?.props?.match?.params?.id ?? null}
              saveToMove={this.state.is_edit}
              preview={this.state.showPreview}
              goPreview={this.goPreview}
              reRender={this.state.render}

              previewFormDetail={previewFormDetail}
              formId={this.state.formId}
              formTitle={this.state.formTitle}

              parentSection={this.state.parentSection}
              selectedSection={this.state.selectedSection}
            />
          </Grid>

        </Grid>

        <AgreeDailog
          loading={this.state.formUpdate.loading}
          saveToMove={this.saveToMove}
          open={this.state.openAgreeDailog}
          skipIsedit={this.skipIsedit}
        />
        <Prompt
          when={this.state.is_edit}
          message={(location) =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
      </div>
    );
  }
}

export default withApollo(withAllContexts(
  withNavBars(withRouter(
    withStyles(styles)(FormConfigure)
  ))
));