import React from 'react';
import Popover from '@material-ui/core/Popover';
import { Avatar, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FORM_COMPONENT_TYPES } from '../../utils';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  imageSize: {
      width: "300px",
      height: "300px"
  }
}));




export default function MouseOverPopover(props) {
  const imageURL = (type) => {
    if(type){
      return type.toLocaleLowerCase().replace(/-\s+/g, '').replace(/\s+/g, '_');
    }
  }
  const classes = useStyles();
  const allComponents = FORM_COMPONENT_TYPES;
  return (
    <div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        open={props.open}
        anchorEl={props.anchorEl}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        onClose={props.handlePopoverClose}
        disableRestoreFocus
      >
        <Typography className={classes.paper} variant={"h6"} style={{fontSize: "17px"}} component={"p"} >
             {allComponents?.data?.filter(getvalue => getvalue.value === props.data?.component)?.[0]?.label}
        </Typography>
        <Divider/>
        {imageURL(allComponents?.data?.filter(getvalue => getvalue.value === props.data?.component)?.[0]?.label) && 
             <Avatar  className={classes.imageSize} alt="Remy Sharp" src={`/images/${imageURL(allComponents?.data?.filter(getvalue => getvalue.value === props.data?.component)?.[0]?.label)}.png`} />
        }
      </Popover>
    </div>
  );
}
