import React from 'react';
import { withNavBars } from '../../HOCs';
import { MasterManagement } from './masterManagement';

class MasterManagementParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {
        return <div>
            <MasterManagement />
        </div>
    }
}

export default withNavBars(MasterManagementParent);