import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Divider,
  FormControlLabel,
  Checkbox,
  Typography,
  Fab,
  Button,
  Tooltip
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FormLayer from "./formComponentLayer";
import SkipComponent from "./skipComponent";
import Skeleton from "@material-ui/lab/Skeleton";
import { AlertContext } from "../../contexts";
import { ArchivedDialog } from '../../components'
import { ComponentToRender } from '../formRenderer/componentToRender'
import { AlertProps, ComponentType, FormComponents, NetWorkCallMethods } from "../../utils";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Payment } from "@material-ui/icons";
import { useRouteMatch } from "react-router";
import { NetworkCall } from "../../networkcall";
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 130px)',
    flexGrow: 1,
    background: theme.palette.background.paper,
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
    overflow: "auto"
  },
  titleHeader: {
    padding: 11
  },
  formFields: {
    paddingBottom: "20px",
    // background: theme.palette.background.default
    background: "rgb(245, 245, 245)",
  },
  addFloatingButton: {
    position: "absolute",
    right: "26px",
    bottom: "30px",
  },
  previewFloatingButton: {
    position: "absolute",
    right: "34px",
    bottom: "104px",
    width: "44px",
    height: "44px"
  },
  saveButton: {
    textTransform: "capitalize",
  },
  sectionTitle: {
    paddingLeft: 10,
  },
  notFoundSectionGrid: {
    height: "inherit"
  },
  notFoundSectionGridItem: {
    textAlign: "center",
  },
  paymentIcon: {
    height: 100,
    width: 100
  },
  titleHeaderParent: {
    position: "sticky",
    top: 0,
    background: theme.palette.background.paper,
    zIndex: 2
  }
}));

const initalData = {
  formError: false,
  loading: true,
  name: "",
  skip: false,
  questions: [],
  delQuestion: []
};

const initalSkipComponent = {
  component: "popup_switch", //smart skip
  label: "",
  required: false,
  options: [],
  is_smart: true,
  priority: 0,
};

export default function Form(props) {

  const match = useRouteMatch();

  //UpsertQuestion Loader
  const { loading } = { loading: false }

  //All Components
  const allComponents = FormComponents;

  const classes = useStyles();

  const [fromData, setFromData] = React.useState(initalData);

  const alert = useContext(AlertContext);

  const check = props.saveToMove;

  const [skipComponentState, setSkipComponentState] = React.useState(
    initalSkipComponent
  );

  //show or hide dialog
  const [open, setOpen] = React.useState(false);

  const confirmDialog = () => {
    setOpen(false)
  }

  const changeSkipComponent = (type) => {

    if (!type) {
      skipComponentState.required = !type;
      fromData.questions.unshift(skipComponentState);
      props.checkFormEdit(true);
      return setFromData({ ...fromData });
    }
    if (fromData.questions.some((val) => val.component === "popup_switch")) {

      fromData.questions.map((val, index) => {

        if (val.component === "popup_switch") {

          skipComponentState.required = !type;
          return fromData.questions.splice(index, 1);
        } else {
          return false;
        }
      });
      setFromData({ ...fromData });
    } else {

      skipComponentState.required = !type;
      setSkipComponentState({
        ...skipComponentState,
      });
    }
    props.checkFormEdit(true);
  };

  const ChangeSkipQuestion = (event, index) => {

    if (fromData.questions.some((val) => val.component === "popup_switch")) {

      fromData.questions.map((val) => {
        if (val.component === "popup_switch") {

          return (val.label = event.target.value);
        } else {
          return false;
        }
      });
      setFromData({ ...fromData });
      setSkipComponentState({
        ...skipComponentState,
        label: event.target.value,
      });
    } else {
      setSkipComponentState({
        ...skipComponentState,
        label: event.target.value,
      });
    }
    props.checkFormEdit(true);
  };

  const addNewComponent = () => {
    let newComp = {
      tag: "",
      label: "",
      question: "",
      dependency: [],
      required: false,
      options: [],
      component: allComponents?.data?.[0]?.value,
      priority: fromData?.questions?.length + 1,
      tableScheme: {
        column: [
          {
            name: 'Column 1',
          },
        ],
        row: [
          {
            name: 'Row 1'
          }
        ]
      }
    };
    fromData.questions.push(newComp);
    setFromData({ ...fromData });
    props.checkFormEdit(true);
  };

  const showPreview = (val) => {
    setOpen(true)
    if (val === true) {
      if (check === true)
        props.goPreview(val);
      else
        props.goPreview(val);
    }
    else
      props.goPreview(val);

  };

  const changeFormSkip = () => {
    fromData.skip = !fromData.skip;
    setFromData({ ...fromData });
    props.checkFormEdit(true);
  };

  const onChangeComponents = (value, index, state) => {
    fromData.questions[index][state] = value;
    setFromData({ ...fromData });

    props.checkFormEdit(true);
  };

  const addDeleteComponents = (value, index, isAdd = true, removeIndex) => {

    if (isAdd) {
      fromData.questions?.[index]?.dependency?.push({
        parentQuestion: { ...value, dependency: [] },
        option: [],
        type: {},
        target: {},
      });
    } else {
      fromData.questions?.[index]?.dependency?.splice(removeIndex, 1);
    }

    setFromData({ ...fromData });

    props.checkFormEdit(true);
  };

  const updateDependency = (key, value, index, parentIndex) => {

    fromData.questions[parentIndex].dependency[index][key] = value;

    setFromData({ ...fromData });

    props.checkFormEdit(true);
  }

  const delectQuestion = (index) => {
    fromData.delQuestion.push(fromData.questions[index])
    fromData.questions.splice(index, 1);
    setFromData({ ...fromData });
    props.checkFormEdit(true);
  };

  useEffect(() => {

    if (!props.section_id) {
      return false;
    }

    let params = {
      section_id: props.section_id ?? null,
    };

    initalSkipComponent.required = false;

    setFromData(initalData);

    setSkipComponentState(initalSkipComponent);

    NetworkCall(
      config.api_url + "/form/section/get",
      NetWorkCallMethods.post,
      params
    ).then((res) => {
      
      const data = {
        loading: false,
        name: props?.formTitle ?? "",
        skip: false,
        questions: [],
      };

      if (res?.data?.data && res?.data?.type === 'Success') {

        let resData = res?.data?.data;

        data.name = resData?.section_name;

        const childUp = resData.framed_questions.map(
          (val) => {
            let tableScheme = {
              column: val?.table_scheme?.column.map(_ => {
                return {
                  component_name: _?.component_name,
                  icon: _?.icon,
                  name: _?.name,
                  ui_component: _?.ui_component,
                }
              }) ?? [],
              row: val?.table_scheme?.row.map(_ => {
                return {
                  name: _?.name
                }
              }) ?? [],
              tag: val?.table_scheme?.tag,
              ui_component: val?.table_scheme?.ui_component
            }

            let child = {
              id: val?.id ?? null,
              question_id: val?.id ?? null,
              label: val?.question_name ?? "",
              required: val?.is_required ?? false,
              component: val?.ui_component ?? allComponents?.data?.[0]?.value,
              priority: val?.priority ?? 1,
              options: val?.options,
              has_dependency_qn: val?.has_dependency_qn,
              tag: val?.tag,
              dependency: val?.dependency?.map(_ => _) ?? [],
              tableScheme: tableScheme
            };

            data.questions.push(child);

            return val;
          }
        );

        data.delQuestion = [];
        setFromData(data);

        return childUp;
      }
      setFromData(data);
    }).catch(error => {
      console.log('error:', error)
    })

    // eslint-disable-next-line 
  }, [props.section_id]);


  const isValidation = () => {
    let isError = false;
    fromData.questions.map((val) => {
      if (
        val?.label?.length < 2 ||
        val?.component?.length < 2 ||
        (ComponentType(val?.component) && val?.options?.length < 2)
      ) {
        let error = {
          labelError: val.label?.length < 2 ? true : false,
          tagError: val.tag?.length < 2 ? true : false,
          componentError: val?.component ? false : true,
          optionError:
            ComponentType(val?.component) && val.options.length < 2
              ? true
              : false,
        };
        val["error"] = error;
        return (isError = true);
      } else {
        val["error"] = {};
        return "";
      }
    });

    if (isError) {
      setFromData({ ...fromData, formError: true });
      return true;
    } else {
      setFromData({ ...fromData, formError: false });
      return false;
    }
  };

  const saveAndUpdate = () => {
    if (isValidation()) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the required fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      return false;
    } else {
      const params = {
        form_id: parseInt(match.params.id),
        section_id: props?.section_id ?? null,
        section_detail_id: props?.section_detail_id ?? null,
        is_skippable: fromData.skip,
        questions: fromData.questions,
        delQuestions: fromData.delQuestion
      };

      NetworkCall(
        config.api_url + "questions/upsert",
        NetWorkCallMethods.post,
        params
      ).then((res) => {
        saveFormMessage({ error: false })
        props.checkFormEdit(false);
      }).catch(error => {
        console.log('error:', error);
        saveFormMessage({ error })
      });

    }
  };

  const saveFormMessage = (res) => {
    let resStatus = res.error;
    alert.setSnack({
      ...alert,
      open: true,
      severity: resStatus ? AlertProps.severity.error : AlertProps.severity.success,
      msg: resStatus ? "Form update Failed !" : "Form updated Successfully !",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.center
    })
  }

  useEffect(() => {
    if (props.save) {
      if (isValidation()) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill the required fields",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        props.handleClose("error");
      } else {
        const params = {
          section_id: props?.section_id ?? null,
          questions: fromData.questions,
          section_detail_id: props?.section_detail_id ?? null,
          is_skippable: fromData.skip,
        };

        NetworkCall(
          config.api_url + "/section/get",
          NetWorkCallMethods.post,
          params
        ).then((res) => {
          saveFormMessage({ error: false })
          props.checkFormEdit(false);
        }).catch(error => {
          console.log('error:', error);
          saveFormMessage({ error })
        });
        props.handleClose();
      }
    }
    // eslint-disable-next-line
  }, [props.save, fromData.questions]);

  return (
    <div className={classes.root}>
      {props.section_id ? (
        <div>

          <Grid
            className={classes.titleHeaderParent}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item className={classes.titleHeader}>
              <Typography variant={"body1"}>
                {"Form Builder "}
              </Typography>
            </Grid>
            {!(fromData?.loading && fromData?.questions?.length === 0) && <Grid item>
              <Button
                color="primary"
                disabled={loading}
                onClick={() => saveAndUpdate()}
                className={classes.saveButton}
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </Grid>
            }
          </Grid>
          <Divider />

          {/* Header of form */}
          {!fromData?.loading && <Grid
            className={classes.sectionTitle}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography
                variant={"body1"}
              >
                {fromData?.name}
              </Typography>
            </Grid>

            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={fromData?.skip ?? false}
                    onChange={changeFormSkip}
                    name="sectionSkip"
                  />
                }
                label="Skip"
              />
            </Grid>

          </Grid>}

          {/* Header of form END */}
          <Divider />

          <Grid className={classes.formFields}>
            {fromData?.loading && fromData?.questions?.length === 0 ? (
              <>
                <Grid style={{ padding: "20px" }}>
                  <Skeleton variant="text" width={"100%"} height={20} />
                  <Skeleton variant="text" width={"100%"} height={48} />
                </Grid>
                <Divider />
                <Grid style={{ padding: "20px" }}>
                  <Skeleton variant="text" width={"100%"} height={20} />
                  <Skeleton variant="text" width={"100%"} height={48} />
                </Grid>
              </>
            ) : (
              <>
                {/* check form is skip */}
                {fromData?.questions?.filter(
                  (val) => val.component === "popup_switch"
                )[0] ? (
                  <SkipComponent
                    // indexOfno={1}
                    changeSkipComponent={changeSkipComponent}
                    ChangeSkipQuestion={ChangeSkipQuestion}
                    data={
                      fromData?.questions?.filter(
                        (val) => val.component === "popup_switch"
                      )[0]
                    }
                  />
                ) : (
                  <SkipComponent
                    changeSkipComponent={changeSkipComponent}
                    ChangeSkipQuestion={ChangeSkipQuestion}
                    data={skipComponentState}
                  />
                )}
                {/* check form is skip END */}
                <Divider />

                {/* Form layer */}
                <FormLayer
                  indexOfno={
                    fromData?.questions?.filter(
                      (val) => val.component === "popup_switch"
                    )[0]
                      ? 1
                      : 0
                  }
                  fromData={fromData?.questions ?? []}
                  onChangeComponents={onChangeComponents}
                  delectQuestion={delectQuestion}
                  addDeleteComponents={addDeleteComponents}
                  updateDependency={updateDependency}
                  parentSection={props.parentSection}
                  selectedSection={props.selectedSection}
                />
                {/* Form layer End */}

                {/* add floating button */}
                <Tooltip title="Preview Form" placement="left">
                  <Fab
                    color="primary"
                    aria-label="preview"
                    className={classes.previewFloatingButton}
                    onClick={() => showPreview(true)}
                  >
                    <VisibilityIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title="Add New Question" placement="left">
                  <Fab
                    color="primary"
                    aria-label="add"
                    className={classes.addFloatingButton}
                    onClick={() => addNewComponent()}
                  >
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </>
            )}
          </Grid>

        </div>
      ) : <Grid
        className={classes.notFoundSectionGrid}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item className={classes.notFoundSectionGridItem}>
          <Payment color="disabled" className={classes.paymentIcon} />
          <Typography color="textSecondary">
            {"Select a Form to use the Form Builder"}
          </Typography>
        </Grid>
      </Grid>}

      {/* form dialog */}
      <ArchivedDialog
        open={open}
        height
        dialogTitle={props.formTitle}
        dialogContent={
          fromData.questions?.length > 0 && fromData.questions?.map((_, i) => {
            return (
              <div style={{ marginTop: 20 }}>
                <ComponentToRender
                  data={{
                    id: _.id,
                    priority: _.priority,
                    ui_component: _.component,
                    is_required: _.required,
                    question: _.label,
                    question_options: _.options,
                    tag: _.tag,
                  }}
                  isReadyOnly={true}
                  // value={
                  //   formsWithAnswer?.[currentForm?.framed_questions?.[0]?.id]
                  //     ?.answer ?? ''
                  // }
                  index={i}
                />
              </div>
            )
          })
        }
        negativeButton
        negativeButtonText="Cancel"
        negativeButtonAction={confirmDialog}
        positiveButton
        positiveButtonText="Ok"
        positiveButtonAction={confirmDialog}
      />
    </div>
  );
}
