import React from 'react';
import { Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  subTitle: {
    marginLeft: theme.spacing(1)
  },
  header: {
    display: "flex",
    alignItems: "center"
  }
}))

export const SubHeader = ({
  hideBackBtn = false,
  hideTitle = false,
  hideSubTitle = false,
  title = '',
  subTitle = '',
  onBackBtnClicked = () => false,
}) => {

  const classes = useStyles();

  return <div className={classes.root}>
    <Grid
      container
      direction="row"
      alignItems="center"
    >
      <Grid item style={{ flex: 1 }}>
        <div className={classes.header}>
          {!hideBackBtn && <Grid item>
            <IconButton onClick={onBackBtnClicked}>
              <KeyboardBackspace htmlColor="rgba(17, 15, 71, 1)" />
            </IconButton>
          </Grid>}

          {!hideTitle && <Grid itemType>
            <Typography variant="body1" color="textPrimary">
              {title}
            </Typography>

          </Grid>}

          {!hideSubTitle && <Grid item className={classes.subTitle}>
            <Typography variant="caption">
              {subTitle}
            </Typography>
          </Grid>}
        </div>
      </Grid>
    </Grid>
  </div>
}