import React from "react";
import { withStyles , Typography} from "@material-ui/core";
// Typography,
// import { LocationIcon } from "../../../assets";
// NextArrow
import { TextFieldAdornment } from "./textFieldAdornment";
import { loadScript } from "./loadScriptFile";

let autoComplete;

const styles = (theme) => ({
  search_location: {
    color: theme.palette.ternary.main,
    textTransform: "uppercase",
    padding: "10px 0px",
    cursor: "pointer"
  },
  checkboxColor: {
    color: theme.palette.ternary.main
  },
  checkboxColorChecked: {
    color: `${theme.palette.ternary.main} !important`
  },
  formControlLabel: {
    fontSize: 12,
    color: theme.palette.ternary.main
  },
  formControlLabelPlacement: {
    marginLeft: 0
  }
});

class SearchLocationInput extends React.Component {
  constructor(props) {
    super(props);
    this.autoCompleteRef = React.createRef(null);
  }
  componentDidMount() {
      
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyBqeACJgp12OLU6EkHeUtFgya2naH1LhrU&libraries=places`,
      () => this.handleScriptLoad(this.autoCompleteRef)
    );
  }

  render() {
    const {
      handleChange,
      value,
      label="Location",
      error=false,
      errorText="error",
      isReadonly,
      isrequired
    } = this.props;
    // openDialogMap
    // const { classes } = this.props;

     this.handleScriptLoad = (autoCompleteRef) => {
  
      autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current
      );
      // REFER THIS FOR PLACES FIELDS
      // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
      autoComplete.setFields([
        "address_components",
        "formatted_address",
        "geometry"
      ]);
      autoComplete.addListener("place_changed", () =>{
      
      const addressObject = autoComplete?.getPlace();
      
      if (addressObject) {
        let _obj = {};
        _obj["address"] = addressObject?.formatted_address;
        _obj["latitude"] = addressObject?.geometry?.location?.lat();
        _obj["longitude"] = addressObject?.geometry?.location?.lng();
        
        this.props?.handleChange(_obj);
        // console.log(autoComplete, "addressObject");
      }

      }
      );
    }

  this.giveMeLabel=()=>{
    
  return <Typography variant="body1">{label} {isrequired && <Typography variant="caption" style={{color:"red"}}>*</Typography>}</Typography>
}


    return (
      <div>
         <TextFieldAdornment
            inputRef={this.autoCompleteRef}
            handleChange={(e) => handleChange(e.target.value)}
            value={value}
            inputLabel={this.giveMeLabel()}
            // adornment={<LocationIcon />}
            error={error}
            errorText={errorText}
            isReadonly={isReadonly}
          />
          
      </div>
    );
  }
}

export default withStyles(styles)(SearchLocationInput);
